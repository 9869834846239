import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table } from 'reactstrap'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { redirectTo, Link } from '@reach/router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import { localDate } from '~utilities/date'
import { USER_TICKETS_QUERY } from '~queries/tickets'

import AccountLayout from '~pages/account/layout/AccountLayout'

const Thead = styled.thead`
  th {
    text-transform: uppercase;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }
`

const Heading = styled.h4`
  line-height: 24px;
  color: ${scssVars.darkBrown};
  margin: 20px 0;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    display: flex;
    font-size: 16px;
    div {
      flex-grow: 1;
      background-color: ${scssVars.gray};
      padding: 15px 0 15px 20px;
    }
    strong {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
    }
    a {
      align-items: center;
      display: flex;
      background-color: ${scssVars.orange};
      color: white;
      padding: 10px;
    }
  }
`

const TableLink = styled(Link)`
  color: ${scssVars.orange};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`

const TicketList = ({ location }) => {
  try {
    if (location && location.search) {
      // TODO: use URLSearchParams
      const search = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      if (search.hasOwnProperty('id')) {
        redirectTo(`/account/tickets/${search.id}`)
      }
    }

    const { data: { myRepairTickets = [] } = {} } = useQuery(USER_TICKETS_QUERY)
    return (
      <AccountLayout
        mobile={
          <>
            <List className="mt-5">
              {myRepairTickets.map((ticket) => (
                <li key={ticket.id} className="my-3">
                  <div className="text-capitalize">
                    <strong>#{ticket.confirmationCode}</strong> |{' '}
                    {ticket.status.replaceAll('_', ' ')}
                  </div>
                  <Link to={`/account/tickets/${ticket.id}`}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </li>
              ))}
            </List>
            <Link
              to="/account"
              className="py-3"
              style={{
                display: 'inline-block',
                textDecoration: 'underline'
              }}
            >
              &#60; Back to Dashboard
            </Link>
          </>
        }
        desktop={
          <>
            <div>
              <Heading>Repair Orders</Heading>
              <Table>
                <Thead>
                  <tr>
                    <th>Repair Order</th>
                    <th>Created On</th>
                    <th>Repair Status</th>
                    <th>Balance Due</th>
                    <th></th>
                  </tr>
                </Thead>
                <tbody>
                  {myRepairTickets.map((ticket) => (
                    <tr key={ticket.id}>
                      <td className="text-uppercase">#{ticket.confirmationCode}</td>
                      <td>{localDate(ticket.createdAt)}</td>
                      <td className="text-capitalize">{ticket.status.replaceAll('_', ' ')}</td>
                      <td>{ticket.price ? `$${ticket.price.toFixed(2)}` : 'N/A'}</td>
                      <td className="text-right">
                        <TableLink className="pr-2" to={`/account/tickets/${ticket.id}`}>
                          View
                        </TableLink>
                        { ticket.returnMethod === 'drop_off' && ticket.status === 'pending_customer_receipt' && <TableLink to={'/customer-services/delivery'}>Schedule a Delivery</TableLink> }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        }
      />
    )
  } catch (e) {
    return (
      <AccountLayout
        mobile={
          <Row className="mt-4">
            <Col>
              <h6 className="text-center">
                An error occured while processing your request.
              </h6>
            </Col>
          </Row>
        }
        desktop={
          <Row className="mt-4">
            <Col>
              <h6 className="text-center">
                An error occured while processing your request.
              </h6>
            </Col>
          </Row>
        }
      />
    );
  }
}

TicketList.propTypes = {
  location: PropTypes.object
}

export default TicketList
