import React from 'react'
import { useQuery } from '@apollo/client'
import { Row, Col, Table } from 'reactstrap'
import { Section, Header, Notice, List, MobileItem, Back } from './List.Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'
import styled from 'styled-components'

import AccountLayout from '~pages/account/layout/AccountLayout'
import { USER_REPAIR_ITEMS } from '~queries/repairItems'
import scssVars from '~components/scss-variables'
import { localDate } from '~utilities/date'

import { useMediaContext } from '~providers/media'

const TableLink = styled(Link)`
  color: ${scssVars.orange};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`

const TableHeader = styled.th`
  font-weight: bold;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
`

function EstimateList () {
  const { isDesktop } = useMediaContext()

  try {
    const { loading, errors, data } = useQuery(USER_REPAIR_ITEMS, { fetchPolicy: 'network-only' })
    const items = (data && data.myRepairItems) || []
    return <Section className="container pl-lg-4">
      <Header>
        <h4>Estimates</h4>
        <p>
          An Estimate is the estimated cost to repair one item based on the services you select.
          Once you&apos;ve created a repair order for your estimate(s) and delivered them to Leather Spa, a technician will evaluate your item(s) carefully and provide a final quote for the cost of the repairs.
        </p>
        <p>
          <strong>
            Please choose a shipping mode below to create a repair order for your estimate(s).
          </strong>
        </p>
        <Notice>
          <em><FontAwesomeIcon icon={faExclamationCircle} /> Please note:</em>
          <p>Your estimate is valid for 2 weeks. Estimates that you do not proceed with will be deactivated and deleted after 2 weeks.</p>
        </Notice>
      </Header>
      <div className="pb-5">
        <div className="text-center">
          {!data && !loading && <h6>No estimates.</h6>}
          {loading && <h6>Loading estimates...</h6>}
          {errors && <h6>An error occured while loading your estimates. Please try again later.</h6>}
        </div>
        <Table className="d-none d-lg-table w-100">
          <thead className="text-uppercase">
            <tr>
              <TableHeader>Item</TableHeader>
              <TableHeader>Created On</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Balance Due</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>{`${item.color} ${item.brand} ${item.apparel.name} `}</td>
                <td>{localDate(item.createdAt)}</td>
                <td className="text-capitalize">{(item.ticket && item.ticket.status) || 'Item not received yet'}</td>
                <td>{item.formattedPrice ? `$${item.formattedPrice}` : 'N/A'}</td>
                <td className="text-right">
                  <TableLink to={`/account/estimates/${item.id}`}>
                    View
                  </TableLink>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <List>
          {items.map(item =>
            <li key={item.id}>
              <MobileItem item={item}>
                <Link to={`/account/estimates/${item.id}`}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </MobileItem>
            </li>
          )}
        </List>
      </div>
      <div className="text-center mb-3 mb-lg-5">
        <Link className={`${isDesktop() ? '' : 'w-100'} mb-2 mb-lg-0 mr-0 ${items.length > 0 ? 'mr-md-2 ' : ''}px-3 btn btn-outline-primary`} to='/repair-wizard'>
          Start Another Repair
        </Link>
        {items.length > 0 && <Link className={`${isDesktop() ? '' : 'w-100'} d-lg-inline-block px-3 btn btn-primary`}  to="/estimates">Choose Shipping Mode</Link>}
      </div>
      <Back className="mt-4">
        <Link to="/account"> Back to Dashboard </Link>
      </Back>
    </Section>
  } catch (e) {
    return (
      <Row className="mt-4">
        <Col><h6 className="text-center">An error occured while processing your request.</h6></Col>
      </Row>
    )
  }
}

const EstimateListView = () => (
  <AccountLayout mobile={<EstimateList />} desktop={<EstimateList />} />
)

export default EstimateListView
