import gql from 'graphql-tag'

export const DELETE_REPAIR_ITEM = gql`
  mutation deleteRepairItem($id: ID!) {
    deleteRepairItem(input: { id: $id }) {
      item {
        id
      }
      errors
    }
  }
`
