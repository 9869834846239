import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHistory, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Nav, NavItem, NavLink, Card } from 'reactstrap'

import Main from '~components/layouts/Main'
import scssVars from '~components/scss-variables'
import { PrivateRoute } from '~components/Routes'
import Layout from '~components/Layout'
import { useUserContext } from '~providers/user'
import { openCalendlyModal } from '~components/Shared/Functions'
import { useMediaContext } from '~providers/media'

import heroImg from '../../../img/heros/account_desktop@2x.png'

const MobileMenu = styled.div`
  display: flex;
  top: -45px;
  height: 35px;
  position: relative;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: none;
  }
`

const Hero = styled.div`
  background: center / cover no-repeat url(${heroImg});
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledCard = styled(Card)`
  background-color: ${scssVars.gray} !important;
  border: none !important;
  position: relative;
  width: 33%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${scssVars.brown};
  font-size: 12px;
  line-height: 1.33;
  a {
    padding: 0 !important;
    color: ${scssVars.brown} !important;
    font-size: 12px !important;
    text-transform: uppercase;
    font-weight: 600;
  }

  &.selected {
    background-color: ${scssVars.brown} !important;

    a, svg {
      color: ${scssVars.white} !important;
    }
  }
`

const NavIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-bottom: 5px;
`

const LogOutLink = styled(NavLink)`
  font-style: italic;
  text-decoration: underline !important;
  color: ${scssVars.orange} !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 0;
`

const StyledNavItem = styled(NavItem)`
  background-color: ${scssVars.lightGray} !important;
  transition: background-color 0.5s ease;
  border-bottom: 1px solid ${scssVars.gray};
  &:hover {
    background-color: ${scssVars.gray} !important;
  }
  a {
    color: ${scssVars.darkBrown};
    text-transform: uppercase;
    font-weight: 600;
    &:hover, &:focus {
      text-decoration: underline;
      color: ${scssVars.darkBrown};
    }
  }
`

const AccountLayout = ({ highlight, mobile, desktop }) => {
  const { useLogOut } = useUserContext()

  const { isDesktop } = useMediaContext()

  const [logOut] = useLogOut()
  const clickLogOut = async () => {
    await logOut()
    navigate('/log-in')
  }

  return (
    <PrivateRoute>
      <Layout useCalendly>
        <Main>
          <section>
            <h1 className="sr-only">My Account</h1>
            <Container className="px-0 pt-lg-3 pb-5">
              <Row className="w-100 mx-0">
                <Col lg={3} className="d-none d-lg-block">
                  <Nav vertical>
                    <StyledNavItem>
                      <Link className="nav-link p-4" to="/account">Dashboard</Link>
                    </StyledNavItem>
                    <StyledNavItem>
                      <Link className="nav-link p-4" to="/account/estimates">Estimates</Link>
                    </StyledNavItem>
                    <StyledNavItem>
                      <Link className="nav-link p-4" to="/account/tickets">Repair Orders</Link>
                    </StyledNavItem>
                    <StyledNavItem>
                      <a className="nav-link p-4" onClick={openCalendlyModal}>Book A Virtual Repair</a>
                    </StyledNavItem>
                    <StyledNavItem>
                      <Link className="nav-link p-4" to="/customer-services/delivery">Schedule a Delivery</Link>
                    </StyledNavItem>
                    <StyledNavItem>
                      <Link className="nav-link p-4" to="/account/profile">My Profile</Link>
                    </StyledNavItem>
                    <StyledNavItem>
                      <LogOutLink className="p-4" onClick={clickLogOut}>Log out</LogOutLink>
                    </StyledNavItem>
                  </Nav>
                </Col>
                <Col lg={9} className="px-0 px-lg-2">
                  <Hero />
                  <MobileMenu>
                    <StyledCard className={`mr-1 ${highlight === 'estimates' ? ' selected' : ''}`}>
                      <Link className="nav-link" to="/account/estimates">
                        <NavIcon icon={faFileInvoiceDollar} />
                        <br/>
                        Estimates
                      </Link>
                    </StyledCard>
                    <StyledCard className={`mx-1 ${highlight === 'past-repairs' ? 'selected' : ''}`}>
                      <Link className="nav-link" to="/account/tickets">
                        <NavIcon icon={faHistory} />
                        <br/>
                        Repair Orders
                      </Link>
                    </StyledCard>
                    <StyledCard className={`ml-1 ${highlight === 'profile' ? 'selected' : ''}`}>
                      <Link className="nav-link" to="/account/profile">
                        <NavIcon icon={faUser} />
                        <br/>
                        My Profile
                      </Link>
                    </StyledCard>
                  </MobileMenu>
                  {isDesktop() ? desktop : mobile}
                </Col>
              </Row>
            </Container>
          </section>
        </Main>
      </Layout>
    </PrivateRoute>
  )
}

AccountLayout.propTypes = {
  mobile: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  desktop: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  highlight: PropTypes.string
}

export default AccountLayout
