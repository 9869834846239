import React from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import styled from 'styled-components'
import { Formik, Form, ErrorMessage } from 'formik'
import DatePicker from 'react-datepicker'

import scssVars from '~components/scss-variables'
import { StyledInput, StyledInputRaw, StyledRadioBtn } from '~components/Checkout/Components'
import { UPDATE_PROFILE_MUTATION } from '~mutations/account'
import { setJWT } from '~providers/user'
import { PROFILE_QUERY } from '~queries/account'

import { useMutation, useQuery } from '@apollo/client'

import CollapseButton from './CollapseButton'
import AccountHeader from './AccountHeader'
import 'react-datepicker/dist/react-datepicker.css'
import '~components/EstimatesDelivery/PickUpForm/react-datepicker-override.scss'


export const StyledLabel = styled.label`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${scssVars.darkBrown};
  font-weight: 900;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    padding-top: 1rem;
  }
`

export const StyledHeading = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${scssVars.darkBrown};
`

export const StyledField = styled(StyledInput)`
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  &::placeholder {
    color: ${scssVars.beige};
  }
`

// export const StyledFieldRaw = styled(StyledInputRaw)`
//   font-family: "Montserrat", sans-serif;
//   background-color: transparent;
// `#

export const StyledFieldRaw =StyledInputRaw

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
`

const RadioLabel = styled.label`
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: ${scssVars.darkBrown};
  letter-spacing: 0.3px;
  line-height: 15px;
  position: relative;
  bottom: 9px;
  left: 9px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 16.8px;
  }
`

const PersonalInfo = () => {
  const { data: { me: { firstName, lastName, gender, birthday, favoriteBrand, phoneNumber } = {} } = {}, loading, error } = useQuery(PROFILE_QUERY)
  const [updateProfile, updateProfileState] = useMutation(UPDATE_PROFILE_MUTATION)
  const{ loading: submitting } = updateProfileState

  const onSubmit = async ({ firstName, lastName, gender, birthday, favoriteBrand, phoneNumber }, actions) => {
    try {
      const { data: { updateProfile: { errors, me } } } = await updateProfile({
        variables: {
          firstName,
          lastName,
          gender,
          birthday,
          favoriteBrand,
          phoneNumber
        }
      })
      setJWT(me.token)
    } catch (e) {
      actions.setErrors(e)
      actions.setSubmitting(false)
    }
  }

  const validate = values => {
    const errors = {}

    if (!values.firstName) {
      errors.firstName = 'First name cannot be blank'
    }

    if (!values.lastName) {
      errors.lastName = 'Last name cannot be blank'
    }

    return errors
  }

  return (
    <>
      <div className="d-lg-none">
        <CollapseButton title="Personal Info">
          { loading ? <Spinner color="primary" /> : (
            <Formik
              initialValues={{
                firstName,
                lastName,
                gender,
                birthday,
                favoriteBrand,
                phoneNumber
              }}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({ isSubmitting, values: { birthday }, setFieldValue }) => (
                <Form>
                  <FieldGroup direction="column">
                    <StyledLabel htmlFor="firstName" className="mb-3">Name:</StyledLabel>
                    <StyledField type="text" name="firstName" />
                    <ErrorMessage name="firstName" component="div" style={{ color: "#FA8200" }} />
                    <StyledField type="text" name="lastName" />
                    <ErrorMessage name="lastName" component="div" style={{ color: "#FA8200" }} />
                  </FieldGroup>
                  <StyledLabel htmlFor="gender" className="mt-2">Gender:</StyledLabel>
                  <FieldGroup style={{ justifyContent: 'start' }}>
                    <div className="mr-5">
                      <StyledRadioBtn type="radio" name="gender" value="female" />
                      <RadioLabel htmlFor="gender">Female</RadioLabel>
                    </div>
                    <div className="mr-5">
                      <StyledRadioBtn type="radio" name="gender" value="male" />
                      <RadioLabel htmlFor="gender">Male</RadioLabel>
                    </div>
                    <div>
                      <StyledRadioBtn type="radio" name="gender" value="other" />
                      <RadioLabel htmlFor="gender">Other</RadioLabel>
                    </div>
                  </FieldGroup>
                  <FieldGroup className="mt-2 mb-4">
                    <StyledLabel htmlFor="date" style={{ paddingTop: '0.5rem', marginRight: '1rem' }}>Birthday:</StyledLabel>
                    <DatePicker
                      className="birthday"
                      placeholderText="mm/dd/yyyy"
                      selected={birthday ? new Date(birthday) : null}
                      onChange={(date) => setFieldValue('birthday', new Date(date))}
                      name="birthday"
                      autoComplete="off"
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FieldGroup>
                  <FieldGroup direction="column">
                    <StyledLabel htmlFor="favoriteBrand">Favorite Brand:</StyledLabel>
                    <StyledField type="text" name="favoriteBrand" placeholder="Enter your favorite brand" />
                  </FieldGroup>
                  <StyledLabel htmlFor="phoneNumber">Phone Number:</StyledLabel>
                  <StyledField type="text" name="phoneNumber" />
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    { submitting ? <Spinner color="primary" /> : 'Submit' }
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </CollapseButton>
      </div>
      <div className="d-none d-lg-block">
        <AccountHeader title="Personal Information">
          { loading ? <Spinner color="primary" /> : (
            <Formik
              initialValues={{
                firstName,
                lastName,
                gender,
                birthday,
                favoriteBrand,
                phoneNumber
              }}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({ values: { birthday }, isSubmitting, setFieldValue }) => (
                <Form className="mb-4" style={{ width: '70%' }}>
                  <Row>
                    <Col lg={4}>
                      <StyledLabel htmlFor="firstName" sm={2}>Name:</StyledLabel>
                    </Col>
                    <Col className="d-flex" lg={8}>
                      <div>
                        <StyledField className="mr-3" type="text" name="firstName" style={{ minWidth: 0 }} />
                        <ErrorMessage name="firstName" component="div" style={{ color: "#FA8200" }} />
                      </div>
                      <div>
                        <StyledField type="text" name="lastName"  style={{ minWidth: 0 }} />
                        <ErrorMessage name="lastName" component="div" style={{ color: "#FA8200" }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <StyledLabel htmlFor="gender">Gender:</StyledLabel>
                    </Col>
                    <Col className="d-flex" lg={8}>
                      <div className="mr-5 mt-2">
                        <StyledRadioBtn type="radio" name="gender" value="female" />
                        <RadioLabel htmlFor="gender">Female</RadioLabel>
                      </div>
                      <div className="mr-5 mt-2">
                        <StyledRadioBtn type="radio" name="gender" value="male" />
                        <RadioLabel htmlFor="gender">Male</RadioLabel>
                      </div>
                      <div className="mr-5 mt-2">
                        <StyledRadioBtn type="radio" name="gender" value="other" />
                        <RadioLabel htmlFor="gender">Other</RadioLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <StyledLabel htmlFor="date">Birthday:</StyledLabel>
                    </Col>
                    <Col lg={8} className="d-flex">
                      <DatePicker
                        className="birthday"
                        placeholderText="mm/dd/yyyy"
                        selected={birthday ? new Date(birthday) : null}
                        onChange={(date) => setFieldValue('birthday', new Date(date))}
                        name="birthday"
                        autoComplete="off"
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <StyledLabel htmlFor="favoriteBrand" className="mb-3">Favorite Brand:</StyledLabel>
                    </Col>
                    <Col lg={8}>
                      <StyledField type="text" name="favoriteBrand" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <StyledLabel htmlFor="phoneNumber">Phone Number:</StyledLabel>
                    </Col>
                    <Col lg={8}>
                      <StyledField type="text" name="phoneNumber" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={{ size: 4, offset: 8}}>
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        { submitting ? <Spinner color="primary" /> : 'Submit' }
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </AccountHeader>
      </div>
    </>
  )
}

export default PersonalInfo
