import React from 'react'
import { Row, Col, Spinner, Alert } from 'reactstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { navigate } from 'gatsby'

import { useUserContext } from '~providers/user'

import { StyledLabel, StyledField } from './PersonalInfo'
import CollapseButton from './CollapseButton'
import AccountHeader from './AccountHeader'

const ChangePasswordForm = () => {
  const { useLogOut, useChangePassword } = useUserContext()
  const [changePassword] = useChangePassword()
  const [logOut] = useLogOut()

  const validate = (values) => {
    const errors = {}
    if (values.passwordConfirmation.length > 3 && values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = 'Password confirmation must match your password.'
    }
    return errors
  }

  const onSubmit = async ({
    currentPassword, password, passwordConfirmation
  }, actions) => {
    try {
      const { data: { changePassword: { success } } } = await changePassword(currentPassword, password, passwordConfirmation)
      if (success) {
        await logOut()
        actions.setSubmitting(false)
        navigate('/log-in')
      } else {
        const err = 'There was an error when attempting to change your password. Please check that your current password is correct.'
        actions.setErrors({ currentPassword: err })
        actions.setSubmitting(false)
      }
    } catch (err) {
      actions.setErrors(err)
      actions.setSubmitting(false)
    }
  }
  return (
    <Formik initialValues={{
      currentPassword: '',
      password: '',
      passwordConfirmation: ''
    }} validate={validate} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <Form className="mb-5">
          <Row className="d-flex justify-content-between">
            <Col sm={4}>
              <StyledLabel>Change Password:</StyledLabel>
              <p>When changing your password, you need to log in again for security purposes.</p>
            </Col>
            <Col sm={4}>
              <StyledField type="password" name="currentPassword" placeholder="Current password" required />
              <StyledField type="password" name="password" placeholder="New password" required />
              <StyledField type="password" name="passwordConfirmation" placeholder="Confirm password" required />
            </Col>
            <Col sm={3}>
              <button className="btn btn-primary my-4" disabled={isSubmitting} type="submit">
                {isSubmitting ? <Spinner color="light" /> : 'Submit'}
              </button>
            </Col>
          </Row>
          {Object.keys(errors).length !== 0 && (
            <Alert color="danger">
              <ErrorMessage name="currentPassword" component="div" />
              <ErrorMessage name="passwordConfirmation" component="div" />
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  )
}

const AccountSettings = () => (
  <>
    <div className="d-lg-none">
      <CollapseButton title="Account Settings">
        <StyledLabel>Change Email Address:</StyledLabel>
        <p>In order to change your email address, please provide your current password.</p>
        <Formik initialValues={{}} validate={{}} onSubmit={{}}>
          {() => (
            <Form>
              <StyledField name="email" placeholder="New email address" />
              <StyledField type="password" name="password" placeholder="Current password" />
              <button className="btn btn-primary my-4" type="submit">Submit</button>
            </Form>
          )}
        </Formik>
        <ChangePasswordForm />
      </CollapseButton>
    </div>
    <div className="d-none d-lg-block">
      <AccountHeader title="Account Settings">
        <Formik initialValues={{}} validate={{}} onSubmit={{}}>
          {() => (
            <Form>
              <Row className="d-flex justify-content-between">
                <Col lg={4}>
                  <StyledLabel>Change Email Address:</StyledLabel>
                  <p>In order to change your email address, please provide your current password.</p>
                </Col>
                <Col lg={4}>
                  <StyledField name="email" placeholder="New email address" />
                  <StyledField type="password" name="password" placeholder="Current password" />
                </Col>
                <Col lg={3}>
                  <button className="btn btn-primary my-4" type="submit">Submit</button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <ChangePasswordForm />
      </AccountHeader>
    </div>
  </>
)

export default AccountSettings
