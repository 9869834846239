import React from 'react'
import { ListGroup } from 'reactstrap'
import { navigate, Link } from 'gatsby'
import styled from 'styled-components'
import { Router } from '@reach/router'

import AccountLayout from './layout/AccountLayout'

import { useUserContext } from '~providers/user'
import scssVars from '~components/scss-variables'

import PersonalInfo from '~components/Account/Profile/PersonalInfo'
import AccountSettings from '~components/Account/Profile/AccountSettings'

import EstimateListView from '~components/Account/Estimates/List'
import SingleEstimate from '~components/Account/Estimates/Single'

import Tickets from '~components/Account/Tickets/List'
import TicketDetails from '~components/Account/Tickets/Detail'

const LogOutNavItem = styled.li`
  padding: 0;
  border: none;
  list-style: none;
  padding: 1rem 0;
  border-top: 1px solid rgba(54, 54, 54, 0.125);
  border-bottom: 1px solid rgba(54, 54, 54, 0.125);
  margin-bottom: 0;
`

const LogOutLink = styled.a`
  font-style: italic;
  text-decoration: underline !important;
  color: ${scssVars.orange} !important;
  text-transform: none !important;
  display: inline-block !important;
  font-weight: 600 !important;
  padding: 0 !important;
`

/* DESKTOP */
const Heading = styled.h1`
  font-weight: 400;
  font-size: 32px;
  color: ${scssVars.darkBrown};
`

const Lead = styled.p`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
`

const TextLink = styled(Link)`
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
  font-family: 'EB Garamond', serif;
`

const Body = () => (
  <>
    <Lead>Welcome to your Digital Repair Dashboard</Lead>
    <p>From here, you can review your repair estimates and create a repair order. If you have already sent your item(s) to us, you can see the status of your repair order.</p>
  </>
)

const Dashboard = () => {
  const { getUser, useLogOut } = useUserContext()
  const user = getUser()
  const [logOut] = useLogOut()
  const clickLogOut = async () => {
    await logOut()
    navigate("/log-in")
  }
  return (
    <AccountLayout
      mobile={(
        <>
          <div className="my-5 px-2">
            <Body />
          </div>
          <ListGroup>
            <LogOutNavItem className="px-2">
              <LogOutLink onClick={clickLogOut}>Logout</LogOutLink>
            </LogOutNavItem>
          </ListGroup>
        </>
      )}
      desktop={(
        <>
          <Heading className="mt-4 mb-3">Hello {user?.first_name},</Heading>
          <Body />
        </>
      )}
    />
  )
}

const Profile = () => {
  return (
    <AccountLayout
      highlight="profile"
      mobile={(
        <>
          <p className="mt-3 px-2">
            The information you provide us is for our use only and will be used
            solely for the purpose of communicating with you regarding your
            estimate and repair. We will never share nor sell this information.
            Your privacy is important to us; we work hard to keep your trust. If
            you have any questions, please check out our{" "}
            <TextLink to="/privacy">privacy policy</TextLink>.
          </p>
          <PersonalInfo />
          {/* <ContactInfo /> */}
          <AccountSettings />
          <Link
            to="/account"
            className="pt-3 px-2"
            style={{ display: "inline-block", fontStyle: "italic", textDecoration: "underline" }}
          >
            &#60; Back to Dashboard
          </Link>
        </>
      )}
      desktop={(
        <>
          <p className="mt-3">
            The information you provide us is for our use only and will be used
            solely for the purpose of communicating with you regarding your
            estimate and repair. We will never share nor sell this information.
            Your privacy is important to us; we work hard to keep your trust. If
            you have any questions, please check out our{" "}
            <TextLink to="/privacy">privacy policy</TextLink>.
          </p>

          <PersonalInfo />
          {/* <ContactInfo /> */}
          <AccountSettings />
        </>
      )}
    />
  )
}

const AccountPage = () => (
  <Router basepath="/account">
    <EstimateListView path="/estimates" />
    <SingleEstimate path="/estimates/:estimateId" />
    <Profile path="/profile" />
    <Tickets path="/tickets" />
    <TicketDetails path="/tickets/:ticketId" />
    <Dashboard path="/"/>
  </Router>
)

export default AccountPage
