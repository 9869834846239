import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import scssVars from '~components/scss-variables'

export const Section = styled.section`
  margin: 0 0 60px 0;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: 25px;
  }
`

export const Header = styled.div`
  h4 {
    display: none;
    border-bottom: 1px solid ${scssVars.gray};
    padding-bottom: 10px;
    margin-bottom: 15px;

    @media only screen and (min-width: ${scssVars.desktopMin}) {
      display: block;
    }
  }
`

export const Notice = styled.div`
  display: flex;
  margin-top: 23px;
  flex-direction: column;

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    flex-direction: row;
  }

  em {
    font-style: normal;
    margin-right: 5px;
    color: ${scssVars.brightOrange};
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

  svg {
    color: ${scssVars.brightOrange};
    top: 1px;
    position: relative;
    font-size: 1.3em;
  }

  p {
    margin-top: -2px;
    padding-left: 30px;

    @media only screen and (min-width: ${scssVars.desktopMin}) {
      padding-left: 0;
    }
  }
`

export const List = styled.ul`
  margin-top: 10px;
  list-style: none;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  font-family: "EB Garamond", serif;

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: 30px;
  }
`

export const ItemName = styled.div`
  font-weight: 700;
`

export const Created = styled.div`
  display: none;
  @media all and (min-width: ${scssVars.desktopMin}) {
    display: table-cell;
  }
`

export const Status = styled.div`
  text-align: center;
`

export const Price = styled.div`
  width: 100px;
  text-align: right;
`

export const MobileItem = ({ item, children }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${scssVars.lightGray};
    margin-bottom: 10px;

    > div {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px;
    }

    ${ItemName}, ${Price} {
      display: inline;
    }

    ${Price}:before {
      position: relative;
      bottom: 2px;
      content: "|";
      margin: 0px 5px 0 5px;
    }

    ${Status} {
      width: 100%;
      margin-top: 7px;
      text-align: left;
    }

    @media all and (min-width: ${scssVars.desktopMin}) {
      display: none;
    }

    a {
      display: block;
      height: 80px;
      width: 40px;
      line-height: 80px;
      text-align: center;
      font-size: 25px;
      color: ${scssVars.white};
      background-color: ${scssVars.brightOrange};
    }
  `

  return <Container>
    <div>
      <div>
        <ItemName>{item.color} {item.brand} {item.apparel.name}</ItemName>
        <Price>${item.formattedPrice}</Price>
      </div>
      <Status>
        <span>Status: </span>
        {(item.ticket && item.ticket.status) || 'Item not received yet'}
      </Status>
    </div>
    {children}
  </Container>
}

MobileItem.propTypes = {
  item: PropTypes.object.isRequired,
  children: PropTypes.object
}

export const Back = styled.div`
  font-weight: 600;
  font-style: italic;

  &:before {
    color: ${scssVars.orange};
    content: " < ";
    position: relative;
    bottom: 1px;
    margin-right: 5px;
  }

  @media all and (min-width: ${scssVars.desktopMin}) {
    display: none;
  }
`
