import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faCheck, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form } from 'formik'
import { SquarePaymentForm } from 'react-square-payment-form'
import SquareContext from 'react-square-payment-form/lib/components/Context'
import { useQuery, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import env from '../../../env'

import AccountLayout from '~pages/account/layout/AccountLayout'
import { Breadcrumbs } from '~components/Services/Intro'
import scssVars from '~components/scss-variables'
import { Print, Disclaimer } from '~components/Account/Estimates/Single.Components'
import { ReusableModal } from '~components/Modals/Modal'
import CC from '~components/EstimatesDelivery/Shared/CC'
import Loading from '~components/Modals/Loading'
import { CREATE_TICKET_PAYMENT } from '~mutations/tickets'
import { ModalContext } from '~providers/ModalContext'
import { SINGLE_TICKET_QUERY } from '~queries/tickets'

const Heading = styled.h1`
  color: ${scssVars.orange};
  font-size: 28px;
  font-weight: 600;
`

const Paragraph = styled.p`
  font-family: 'Montserrat', sans-serif;
  line-height: 1.375;
`

const PrintButton = styled(Print)`
  border: 2px solid ${scssVars.orange} !important;
`

const EstimateHeading = styled.h2`
  font-weight: 700;
  letter-spacing: 0;
  font-size: 22px;
`

const EstimateDetailsTable = styled.table`
  font-family: 'Montserrat', sans-serif;
  th, td {
    border-top: none !important;
  }
`

const EstimatedPrice = styled.span`
  float: right;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  color: ${scssVars.orange};
  font-weight: 200;
`

const List = styled.ul`
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 15px 0 !important;
  padding-left: 17px !important;
  li {
    font-size: 14px;
  }
`

const Hr = styled.hr`
  margin-top: 2rem;
  margin-bottom: 2rem;
  &:last-of-type {
    border-color: ${scssVars.darkBrown};
  }
`

const PriceRow = styled(Row)`
  border: 2px solid ${scssVars.brightOrange};
`

const PriceHeading = styled.h3`
  color: ${scssVars.orange};
  font-weight: 600;
`

const PriceTable = styled.table`
  font-family: 'Montserrat', sans-serif;
`

const Button = styled.button`
  display: block;
  margin: 15px auto 50px auto;
  width: 450px;
  max-width: 95%;
`

const NewTicketHeading = styled.h5`
  letter-spacing: -0.01em;
  font-size: 18px;
  font-weight: 600;
`

function prepPayload(values, nonce, buyerVerificationToken) {
  return {
    variables: {
      ticketId: values.ticketId,
      payment: {
        nonce: nonce,
        amountCents: Math.round(values.amount * 100)
      },
      token: buyerVerificationToken
    }
  }
}

const FormContents = ({ ccErrors, isSubmitting }) => {
  const squareContext = useContext(SquareContext)
  return (
    <Form>
      <CC errors={ccErrors} />
      <Button
        type="submit"
        className="btn btn-primary"
        disabled={isSubmitting}
        onClick={squareContext.onCreateNonce}
      >
        Pay for Repairs
      </Button>
    </Form>
  )
}

FormContents.propTypes = {
  ccErrors: PropTypes.array,
  isSubmitting: PropTypes.bool
}

const PaymentModal = ({ modalOpen, toggleModal, initialValues }) => {
  const [ccErrors, setCCErrors] = useState([])
  const [createTicketPayment, { loading }] = useMutation(CREATE_TICKET_PAYMENT)
  return modalOpen ? (
    <ReusableModal isOpen={modalOpen} toggleModal={toggleModal} backdropClassName="modal-backdrop">
      {loading ? (
        <Loading className="p-5" />
      ) : (
          <div className="p-5">
            <h5>Enter Credit Card Information</h5>
            <Formik initialValues={initialValues} onSubmit={() => { }}>
              {({ isSubmitting, setSubmitting, values }) => {
                const cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken) => {
                  const filteredErrors = errors ? errors.filter(e => ![undefined, null, ''].includes(e)) : null
                  if (filteredErrors && filteredErrors.length > 0) {
                    setCCErrors(filteredErrors)
                    setSubmitting(false)
                  } else {
                    setCCErrors([])
                    const payload = prepPayload(values, nonce, buyerVerificationToken)
                    try {
                      await createTicketPayment(payload)
                      toggleModal(!modalOpen)
                    } catch (e) {
                      Sentry.captureException(e)
                    }
                  }
                }
                return (
                  <SquarePaymentForm
                    sandbox={env.SQUARE_SANDBOX}
                    applicationId={env.SQUARE_APPLICATION_ID}
                    locationId={env.SQUARE_LOCATION_ID}
                    cardNonceResponseReceived={(errors, nonce, cardData, buyerVerificationToken) => {
                      return cardNonceResponseReceived(errors, nonce, cardData, buyerVerificationToken)
                    }}
                  >
                    <FormContents isSubmitting={isSubmitting} ccErrors={ccErrors} />
                  </SquarePaymentForm>
                )
              }}
            </Formik>
          </div>
        )}
    </ReusableModal>
  ) : null
}

PaymentModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

const Error = () => (
  <Row className="mt-4">
    <Col>
      <h6 className="text-center">
        An error occured while processing your request.
      </h6>
    </Col>
  </Row>
)

const TicketCreatedModal = ({ modalOpen, toggleModal, ticket }) => {
  return modalOpen ? (
    <ReusableModal isOpen={modalOpen} toggleModal={toggleModal} backdropClassName="modal-backdrop">
      <div className="p-5">
        <NewTicketHeading>Nicely done. <br />Your Repair Order has been created.</NewTicketHeading>
        <p>
          Now that you've created your repair order, make sure to first print your packing slip and package it neatly alongside your item(s).
        </p>

        {(ticket.type === 'mail_in') && <>
          <p>
            If you do not have access to a printer, you can simply write your repair order number on the outside of your package or on a note and send it in with your item(s).
          </p>
          <p>
            You will receive a copy of your packing slip via email and you can also retrieve it from your account dashboard anytime.
          </p>
          <p>
            <strong>
              If you purchased a UPS label, your shipping label and packing slip will be emailed to you.
            </strong>
          </p>
          <p>
            <strong>
              If you purchased The UPS Store dropoff service, you can find your nearest location by <a className="text-link" href="https://www.theupsstore.com/tools/find-a-store" target="_blank">clicking here</a>.
            </strong>
          </p>
        </>}

        {(ticket.type === 'pick_up') && <>
          <p>
            If you do not have access to a printer, you can simply write your repair order number on the outside of your package or on a note and send it in with your item(s).
          </p>
          <p>
            On the day of your pickup, we will notify you via email or text message that our specialist is on the way to collect your order.
          </p>
          <p>
            You will receive a copy of your packing slip via email and you can also retrieve it from your account dashboard anytime.
          </p>
        </>}

        {(ticket.type === 'drop_off') && <>
          <p>
            If you do not have access to a printer, you can simply write your repair order number on the outside of your package or on a note and drop it off at any Leather Spa store with your item(s).
          </p>
          <p>
            We will take care of the rest for you via our online platform.
          </p>
          <p>
            You will receive a copy of your packing slip via email and you can also retrieve it from your account dashboard anytime.
          </p>
          <p>
            <strong>
              Locate the most convenient Leather Spa <a className="text-link" href="https://leatherspa.com/locations/" target="_blank">location</a>.
            </strong>
          </p>
        </>}

        <p>
          If you have any questions or concerns, feel free to contact our customer support by clicking "Need Help?" below.
        </p>

        <Row className="mt-4">
          <Col className="pr-lg-1">
            <button className="btn btn-primary w-100" onClick={() => toggleModal(false)}>View Details</button>
          </Col>
          <Col className="pl-lg-1">
            <a className="btn btn-outline-primary w-100" href={`${env.API_URL}/rest/repair/tickets/${ticket.id}.pdf`} target="_blank">
              View Print&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </Col>
        </Row>
      </div>
    </ReusableModal>
  ) : null
}

TicketCreatedModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired
}

const Details = ({
  items,
  ticket,
  isBalancePaid,
  initialValues,
  firstView
}) => {
  const { modalOpen, toggleModal } = useContext(ModalContext)
  useEffect(() => {
    if (firstView) toggleModal(true)
  }, [])

  return (
    <>
      {(modalOpen && ticket.price) && <PaymentModal modalOpen={modalOpen} toggleModal={toggleModal} initialValues={initialValues} />}
      {(modalOpen && firstView) && <TicketCreatedModal modalOpen={modalOpen} toggleModal={toggleModal} ticket={ticket} />}
      <div className="px-3">
        <Breadcrumbs className="ml-0 my-3">
          <li>
            <Link to="/account">Account</Link>
          </li>
          <li>
            <Link to="/account/tickets">Repair Orders</Link>
          </li>
          <li>Details</li>
        </Breadcrumbs>
        <Row className="my-3">
          <Col>
            <Heading>
              <span className="d-none d-lg-inline">Repair&nbsp;</span>Order&nbsp;#{ticket.confirmationCode}
            </Heading>
          </Col>
          <Col className="text-right">
            <PrintButton as='a' role="button" className="btn btn-link text-right" href={`${env.API_URL}/rest/repair/tickets/${ticket.id}.pdf`} target="_blank">
              <FontAwesomeIcon icon={faPrint} />&nbsp;Print
            </PrintButton>
          </Col>
        </Row>
        {items.map((item, i) => (
          <span key={item.id}>
            <Row className="mb-2">
              <Col className="flex-grow-1">
                <EstimateHeading>Estimate #{i + 1}</EstimateHeading>
                <EstimateDetailsTable className="table-sm">
                  <tbody>
                    <tr>
                      <th scope="row">Item:</th>
                      <td>{item.apparel.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Brand:</th>
                      <td>{item.brand}</td>
                    </tr>
                    <tr>
                      <th scope="row">Color:</th>
                      <td>{item.color}</td>
                    </tr>
                    <tr>
                      <th scope="row">Material:</th>
                      <td>{item.material}</td>
                    </tr>
                    <tr className="align-top">
                      <th scope="row">Description:</th>
                      <td>{item.description}</td>
                    </tr>
                  </tbody>
                </EstimateDetailsTable>
              </Col>
              {item.photoUrl && (
                <Col className="d-none d-md-block flex-grow-0">
                  <img src={`${item.photoUrl}&fit=crop&w=125&fm=jpg`} />
                </Col>
              )}
            </Row>
            <List>
              {item.options.map((option) => (
                <li key={option.id}>
                  <span>
                    {option.name}
                  </span>
                  <span className="float-right font-weight-bold" style={{ color: scssVars.orange }}>
                    {option.formattedPrice}
                  </span>
                </li>
              ))}
            </List>
            {item.advisorNote && (
              <Paragraph>
                <strong>Note from your Repair Advisor:&nbsp;</strong>{item.advisorNote}
              </Paragraph>
            )}
            <Hr />
          </span>
        ))}
        {ticket.price && (
          <Col lg={12}>
            <PriceTable className="table">
              <tbody>
                <tr>
                  <td className="font-weight-bold border-top-0">
                    Subtotal:
                  </td>
                  <td className="text-right border-top-0">
                    ${ticket.subtotal.toFixed(2)}
                  </td>
                </tr>
                {ticket.isRush && (<tr>
                  <td className="font-weight-bold">Rush Fee:</td>
                  <td className="text-right">$20</td>
                </tr>)}
                {ticket.shippingFee && (<tr>
                  <td className="font-weight-bold">Return Shipping Fee:</td>
                  <td className="text-right">${ticket.shippingFee.toFixed(2)}</td>
                </tr>)}
              </tbody>
            </PriceTable>
          </Col>
        )}
        <PriceRow className="p-4 mt-4 mx-0">
          <Col lg={6}>
            <PriceHeading>
              {ticket.price ? 'Total Price' : 'Estimated Price'}
            </PriceHeading>
            <Paragraph style={{ marginBottom: '0.5rem' }}>
              {ticket.price
                ? 'This is the final price for your repair services and return shipping.'
                : 'This is just an estimate and is not a guarantee of cost for services. Actual cost may change once we have received and evaluated your item.'}
            </Paragraph>
          </Col>
          <Col lg={6} className="pt-md-3">
            <EstimatedPrice className="text-lg-right">
              ${ticket.price
                ? ticket.amountDue.toFixed(2)
                : ticket.formattedPrice}
            </EstimatedPrice>
          </Col>
        </PriceRow>
        <Disclaimer className="text-left text-md-center my-5">
          {ticket.advisorNote && (
            <Paragraph style={{ fontSize: '20px' }}>
              <strong>Note from your Repair Advisor:&nbsp;</strong>{ticket.advisorNote}
            </Paragraph>
          )}
          <br />
          <span className="text-uppercase">
            <FontAwesomeIcon icon={faCheck} />I Have Agreed To The Terms Laid Out In The Leather Spa&nbsp;<a href="/operational-policies" target="_blank">Operations Policies</a>
          </span>
        </Disclaimer>
        <Row className="text-center justify-content-md-center">
          <Col md={4} className="mb-3 mb-md-0 pr-md-1">
            <a className="btn btn-outline-primary w-100" href={`mailto:repairs@leatherspa.com?subject=Repair Order Support: ${ticket.confirmationCode}`}>
              Need Help?
            </a>
          </Col>
            {ticket.price > 0 ? (
              <Col md={4} className="mb-3 mb-md-0 pr-md-1">
                <button onClick={() => toggleModal(!modalOpen)} className="btn btn-primary w-100" disabled={isBalancePaid}>
                  {isBalancePaid ? 'Balance Paid' : 'Approve And Pay For Repairs'}
                </button>
              </Col>
            ) : null}
            {ticket.returnMethod === 'drop_off' && ticket.status === 'pending_customer_receipt' ? (
              <Col md={4} className="mb-3 mb-md-0 pr-md-1">
                <Link to={'/customer-services/delivery'} className="btn btn-outline-primary w-100">
                  Schedule a Delivery
                </Link>
              </Col>
            ) : null}
        </Row>
      </div>
    </>
  )
}

Details.propTypes = {
  items: PropTypes.array,
  ticket: PropTypes.object,
  isBalancePaid: PropTypes.bool,
  initialValues: PropTypes.object,
  firstView: PropTypes.bool
}

const TicketLoading = () => (
  <Row className="mt-4">
    <Col><h6 className="text-center">Loading...</h6></Col>
  </Row>
)

const TicketDetails = ({ ticketId: id, location }) => {
  const firstView = location.state?.firstView || false
  try {
    const { loading, error, data } = useQuery(SINGLE_TICKET_QUERY, { variables: { id } })

    if (loading) {
      return <AccountLayout mobile={<TicketLoading />} desktop={<TicketLoading />} />
    }

    const { myRepairTicket } = data
    const isBalancePaid = myRepairTicket.price <= myRepairTicket.amountPaid
    const initialValues = {
      ticketId: myRepairTicket.id,
      nonce: '',
      token: '',
      amount: myRepairTicket.amountDue
    }

    return (
      <AccountLayout
        mobile={
          <Details
            items={myRepairTicket.items}
            ticket={myRepairTicket}
            isBalancePaid={isBalancePaid}
            initialValues={initialValues}
            firstView={firstView}
          />
        }
        desktop={
          <Details
            items={myRepairTicket.items}
            ticket={myRepairTicket}
            isBalancePaid={isBalancePaid}
            initialValues={initialValues}
            firstView={firstView}
          />
        }
      />
    )
  } catch (e) {
    Sentry.captureException(e)
    return (
      <AccountLayout mobile={<Error />} desktop={<Error />} />
    )
  }
}

TicketDetails.propTypes = {
  location: PropTypes.object
}

export default TicketDetails
