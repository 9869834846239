import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import { Container, Row, Col } from 'reactstrap'
import { Link }  from 'gatsby'

export const Section = styled(Container)`
  font-size: 16px;
  letter-spacing: 0.54px;

  h3 {
    font-weight: 700;

    &.orange {
      color: ${scssVars.brightOrange};
    }
  }

  h6 {
    font-weight: 700;
  }

  a {
    font-weight: 600;
  }

  p {
    margin-top: -4px;
  }

  ul {
    margin: -10px 0 0 0;
    padding: 0 0 0 17px;
  }

  img {
    width: 300px;
    height: auto;
    max-width: 100%;
  }
`

export const Centered = styled.div`
  text-align: center;

  @media all and (min-width: ${scssVars.tabletMin}) {
    :before {
      content: 'Status: ';
    }
  }
`

export const Print = styled(Link)`
  position: relative;
  bottom: 0;

  && {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    text-transform: capitalize;
  }

  svg {
    margin-right: 5px;
  }
`

export const Backlink = styled(Link)`
  display: inline;
  font-size: 13px;
  color: ${scssVars.darkBrown};
  font-weight: 500;
  position: relative;
  line-height: 28px;

  @media all and (max-width: ${scssVars.tabletMax}) {
    && {
      font-weight: 400;
    }
  }

  a {
    font-weight: inherit;
    color: inherit;

    :hover {
      color: inherit;
    }
  }
`

export const Disclaimer = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  position: relative;
  a {
    font-style: italic;
  }

  svg {
    color: ${scssVars.brightOrange};
    font-size: 25px;
    position: relative;
    top: 2px;
    left: -5px;
  }
`

export const Price = styled.div`
  color: ${scssVars.brightOrange};
  font-weight: 700;
  font-size: 20px;
  text-align: right;
`

export const Rush = styled(Row)`
  h6 {
    display: inline;
  }

  svg {
    color: ${scssVars.brightOrange};
    margin-right: 8px;
    position: relative;
    bottom: 2px;
  }

  span:before {
    content: " — "
  }
`

const GrayBox = styled(Container)`
  background-color: ${scssVars.gray};
  text-align: center;
  overflow: hidden;

  @media all and (max-width: ${scssVars.tabletMax}) {
    && {
      width: calc(100vw - 2px);
      max-width: none;
      position: relative;
      margin-left: calc((100% - 100vw) / 2);
    }

    h6 {
      font-size: 22px;
    }
  }

  h6 {
    letter-spacing: 0.16px;
    font-weight: 700;
  }

  button {
    color: ${scssVars.brightOrange};
    font-weight: 600;
    letter-spacing: 0.35px;
    font-size: 14px;
    font-style: italic;
  }
`

export const CancelEstimate = ({ deleteTicket, id, deletingTicket, ticketDeleted, ...props }) => <GrayBox {...props}>
  <Row>
    <Col className="mt-4 pt-2">
      <h6>Cancel this estimate?</h6>
    </Col>
  </Row>
  <Row className="mt-2 pr-5 pl-5 p-md-0">
    <Col>
      <p>
        When you cancel your estimate, it is marked for deletion. Your estimate is valid for 2 weeks.
        Estimates that you do not proceed with will be deactivated and deleted after 2 weeks automatically.
      </p>
    </Col>
  </Row>
  <Row className="pb-3">
    <Col>
      <button type="button" className="btn btn-link" onClick={() => deleteTicket({ variables: { id } })} disabled={deletingTicket || ticketDeleted}>
        {deletingTicket
          ? 'Cancelling estimate...'
          : ticketDeleted ? 'Estimate canceled' : 'Cancel this estimate'
        }
      </button>
    </Col>
  </Row>
</GrayBox>

CancelEstimate.propTypes = {
  deleteTicket: PropTypes.func,
  id: PropTypes.string,
  deletingTicket: PropTypes.bool,
  ticketDeleted: PropTypes.object
}
