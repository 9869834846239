import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'

const StyledCollapse = styled(Collapse)`
  background-color: ${scssVars.lightGray};
  border-top: 1px solid ${scssVars.gray};
`

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 0;
  padding: 1rem;
  font-size: 16px;
  font-weight: 900;
  color: ${scssVars.darkBrown};
  background-color: ${scssVars.lightGray};
  text-transform: uppercase;
`

const CollapseButton = ({ children, title }) => {
  const [collapseOpen, setCollapse] = useState(false)

  return (
    <div className="mb-3 px-2">
      <StyledButton onClick={() => setCollapse(!collapseOpen)} type="button">
        {title}
        <FontAwesomeIcon className="mt-1" icon={collapseOpen ? faMinus : faPlus} />
      </StyledButton>
      <StyledCollapse isOpen={collapseOpen} className="px-3 py-3">
        {children}
      </StyledCollapse>
    </div>
  )
}

CollapseButton.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default CollapseButton
