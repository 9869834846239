import gql from 'graphql-tag'

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress (
    $addressId: ID!,
    $address: AddressInput!
  ) {
    updateAddress(input: {
      addressId: $addressId,
      address: $address
    }) {
      errors
    }
  }
`

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile ($firstName: String, $lastName: String, $gender: String, $birthday: String, $favoriteBrand: String, $phoneNumber: String) {
    updateProfile(input: {
      firstName: $firstName,
      lastName: $lastName,
      gender: $gender,
      birthday: $birthday,
      favoriteBrand: $favoriteBrand,
      phoneNumber: $phoneNumber
    }) {
      me {
        id
        email
        name
        firstName
        lastName
        birthday
        favoriteBrand
        phoneNumber
        token
      }
      errors
    }
  }
`

// Update token
