import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'formik'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import scssVars from '~components/scss-variables'

export const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: ${scssVars.darkBrown};
`

export const FormWrapper = styled.div`
  margin-bottom: ${props => props.marginBottom || '0.25rem'};
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 55%;
  }
`

export const StyledLabel = styled.label`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: ${props => props.fontWeight || '400'};
  letter-spacing: 0.35px;
  line-height: 16.8px;
  color: ${scssVars.darkBrown};
  position: relative;
  left: 15px;
  bottom: 9px;
  margin-top: ${props => props.marginTop || '5px !important'};
  margin-bottom: 0 !important;
`

export const StyledInput = styled(Field)`
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  border-bottom: 1px solid ${scssVars.beige};
  margin-bottom: 0.6rem;
  box-shadow: none;
  color: ${scssVars.darkBrown};
  padding: 0.4rem 0.75rem;
  &::placeholder {
    color: ${scssVars.darkBrown};
    opacity: 1;
  }
`

export const StyledInputRaw = styled.input`
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  border-bottom: 1px solid ${scssVars.beige};
  margin-bottom: 0.6rem;
  box-shadow: none;
  color: ${scssVars.darkBrown};
  padding: 0.4rem 0.75rem;
  &::placeholder {
    color: ${scssVars.darkBrown};
    opacity: 1;
  }
`

export const StyledRadioBtn = styled(Field)`
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid ${scssVars.beige};
  box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  padding: 12px !important;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  &:checked:after {
    content: " ";
    width: 16px;
    height: 16px;
    border-radius: 50px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: ${scssVars.orange};
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
    font-size: 32px;
  }
  &:checked {
    background-color: #e9ecee;
    color: ${scssVars.orange};
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
  }
  &:active,
  &:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
`

export const StyledCheckBox = styled(Field)`
  -webkit-appearance: none;
  border: 1px solid #bcb5a4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 11px !important;
  display: inline-block;
  position: relative;
  &:active,
  &:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  &:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }
  &:checked::after {
    content: "✔";
    font-size: 42px;
    position: absolute;
    color: ${scssVars.orange};
    left: 4px;
    top: -28px;
  }
`

export const StyledDropdown = styled(Dropdown)`
  button {
    width: 100% !important;
    margin-bottom: .6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    color: ${scssVars.darkBrown};
    border-color: transparent;
    text-transform: none;
    padding: 0.4rem 0.75rem !important;
    &:hover {
      background-color: #d3d3d3 !important;
    }
    &:focus {
      background-color: #c4c3c1 !important;
    }
    &:disabled {
      background-color: #d3d3d3 !important;
      opacity: 1 !important;
    }
  }
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-family: 'Montserrat', sans-serif !important;
  background: none !important;
  border: none !important;
  border-bottom: 1px solid ${scssVars.beige} !important;
  color: ${scssVars.darkBrown} !important;
  .toggle-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 170px;
  overflow: scroll;
`

export const StyledDropdownItem = styled(DropdownItem)`
  margin-bottom: 0 !important;
  padding-top: 0.5rem !important;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  button {
    width: 155px;
  }
  .place-order,
  a {
    width: 368px;
  }
  @media only screen and (max-width: ${scssVars.desktopMin}) {
    justify-content: ${props => props.justify || 'end'};
  }
`

export const ButtonLink = styled.button`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.35px;
  font-style: italic;
  text-decoration: underline;
  border: none;
  background: none;
  color: ${scssVars.orange};
`

export const capitalize = (string) => {
  let name = string.replace(/(^|\s)[a-z]/g, (str) => {
    return str.toUpperCase()
  })
  return name
}

export const RadioButtons = ({ values }) => {
  const shipping = [
    'UPS Ground Promotion — $7.50',
    'UPS Ground — $9.77',
    'UPS Second Day Air — $21.29',
    'UPS Next Day Air — $38.64'
  ]
  return (
    <>
      {
        shipping.map((key, index) => {
          return (
            <FormWrapper key={index}>
              <StyledRadioBtn type="radio" name="shipping" value={key} checked={key === values.shipping} />
              <StyledLabel fontWeight="100">
                {key}
              </StyledLabel>
            </FormWrapper>
          )
        })
      }
    </>
  )
}

RadioButtons.propTypes = {
  values: PropTypes.object
}
