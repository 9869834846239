/**
 * Format the result into dollars and set the max to "up"
 * @param {*} result
 */
export function formatResult (result) {
  const [min, max, indefiniteMax] = result

  if (indefiniteMax) {
    return `$${min / 100}-UP`
  } else if (min && max && min !== max) {
    return `$${min / 100}-${max / 100}`
  } else if (min) {
    return `$${min / 100}`
  } else {
    return null
  }
}

/**
 * Merge field values of the first and second forms for submission
 * @param {*} desc
 * @param {*} services
 */
export function mergeForms (description, values) {
  return {
    ...description,
    additionalInformation: values.additionalInformation,
    options: values.options,
    isRush: values.isRush
  }
}

export function sumOptions (options) {
  return options.reduce(([min, max, indefiniteMax], value) => [
    min + value.minPrice,
    value.maxPrice ? max + value.maxPrice : max + value.minPrice,
    ...value.indefiniteMax ? [true] : [indefiniteMax]
  ], [0, 0, false])
}
