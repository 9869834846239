import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { Row, Col } from 'reactstrap'
import { Link }  from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCrown } from '@fortawesome/free-solid-svg-icons'

import { SINGLE_REPAIR_ITEM } from '~queries/repairItems'
import { DELETE_REPAIR_ITEM } from '~mutations/repairItems'
import AccountLayout from '~pages/account/layout/AccountLayout'

import scssVars from '~components/scss-variables'
import { sumOptions, formatResult } from '~components/RepairWizard/Functions'
import { CancelEstimate, Section, Price, Backlink, Disclaimer, Rush } from '~components/Account/Estimates/Single.Components'

const EstimatedPrice = styled.h1`
  letter-spacing: 0.025em;
`

const PriceRow = styled(Row)`
  border: 2px solid ${scssVars.brightOrange};
`

const List = styled.ul`
  padding-left: 0;
  font-family: 'Montserrat', sans-serif;
  > li {
    margin-top: 15px;
    strong {
      display: block;
      margin: 15px 0 10px;
    }
    padding-bottom: 15px;
    &:last-of-type {
      border-bottom: none;
    }
  }
  ul {
    list-style: initial;
    padding-left: 15px;
    > li {
      margin-bottom: 10px;
    }
  }
`

const Paragraph = styled.p`
  letter-spacing: 0.025em;
  line-height: 1.375;
`

function mergeOptionsByCategory (options) {
  const categories = {}

  options.forEach(option => {
    if (!categories[option.category.name]) {
      categories[option.category.name] = []
    }

    categories[option.category.name].push(option)
  })

  return categories
}

function DetailedEstimate ({ estimateId: id }) {
  const [deleteTicket, { loading: deletingTicket, data: ticketDeleted, errors: deleteErrors }] = useMutation(DELETE_REPAIR_ITEM)

  try {
    const { loading, data } = useQuery(SINGLE_REPAIR_ITEM, { variables: { id } })
    const item = data && data.repairItem
    const categories = item && mergeOptionsByCategory(item.options || [])

    if (loading) {
      return <Row className="mt-4">
        <Col><h6 className="text-center">Loading...</h6></Col>
      </Row>
    }

    return <Row className="mr-3 ml-1 p-lg-0 m-lg-0">
      <Col className="p-0">
        <Section>
          <div className="d-none d-lg-block">
            <Row className="mt-5 ml-1 border-bottom">
              <Col className="p-0">
                <h3>
                  Your Estimate
                </h3>
              </Col>
              <Col xs={5} className="p-0 text-left text-lg-right">
                <Backlink to="/account/estimates">
                  <span>&lt; Back to all Estimates</span>
                </Backlink>
              </Col>
            </Row>
          </div>
          <div className="d-lg-none">
            <Row>
              <Col>
                <Backlink to="/account/estimates">
                  <span>&lt; All Estimates</span>
                </Backlink>
              </Col>
            </Row>
          </div>
          <Row className="mt-2 mt-lg-4">
            <Col lg={7}>
              <p>Here is the information we collected from your estimate submission.</p>
              <p>
                <strong>
                  Once you've created an estimate for your item(s), please <Link to="/estimates" className="text-link">select a shipping mode</Link> to receive your repair order number.
                </strong>
              </p>
            </Col>
            <Col md={5} className="d-none d-lg-flex pr-0">
              <CancelEstimate {...{ deleteTicket, id, deletingTicket, ticketDeleted }} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <h3 className="orange">Item Details:</h3>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4}><h6>Type:</h6></Col>
            <Col xs={8} className="pr-0">{item.apparel && item.apparel.name}</Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4}><h6>Brand:</h6></Col>
            <Col xs={8} className="pr-0">{item.brand}</Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4}><h6>Color:</h6></Col>
            <Col xs={8} className="pr-0">{item.color}</Col>
          </Row>
          <Row className="mb-2">
            <Col xs={4}><h6>Material:</h6></Col>
            <Col xs={8} className="pr-0">{item.material}</Col>
          </Row>
          {item.description &&
            <Row className="mb-2">
              <Col xs={4}><h6>Description:</h6></Col>
              <Col xs={8} className="pr-0">{item.description}</Col>
            </Row>
          }
          {item.photoUrl &&
            <Row className="mb-2">
              <Col xs={4}><h6>Photo:</h6></Col>
              <Col xs={8} className="pr-0">
                <img src={`${item.photoUrl}&fit=crop&h=300&fm=jpg`} />
              </Col>
            </Row>
          }
          <Row className="mt-4 mb-2">
            <Col>
              <h3 className="orange">Repair Services:</h3>
            </Col>
          </Row>
          {categories && Object.entries(categories).map(([category, options]) =>
            <Row key={category} className="border-bottom mb-2 ml-1 pb-2 pr-0">
              <Col xs={8} className="pt-1 pl-0">
                <h6>{category}</h6>
                <List>
                  {options.map((option, j) => (
                    <li key={j}>
                      <b style={{fontWeight:600}}>{option.name}</b>
                      <br />
                      Your comment: {option.comment}
                    </li>
                  ))}
                </List>
              </Col>
              <Col xs={4} className="pr-1">
                <Price>{formatResult(sumOptions(options))}</Price>
              </Col>
            </Row>)}
          {item.isRush &&
            <Rush className="pr-0 pl-1">
              <Col xs={10} className="pt-1">
                <FontAwesomeIcon icon={faCrown} />
                <h6>Rush Service</h6>
                <span>We will rush to complete the repair in half our normal lead time.</span>
              </Col>
              <Col xs={2} className="pr-0">
                <Price>20</Price>
              </Col>
            </Rush>
          }
          <PriceRow className="p-4 mt-5 ml-1 pr-0">
            <Col lg={6}>
              <h3>Total Estimate</h3>
              <Paragraph>
                This is just an estimate and is not a guarantee of cost for services.
                Actual cost may change once we have received and evaluated your item.
              </Paragraph>
            </Col>
            <Col lg={6} className="text-right pt-md-3">
              <EstimatedPrice>{item.formattedPrice}</EstimatedPrice>
            </Col>
          </PriceRow>
          <Row className="justify-content-center">
            <Col md={10} className="text-left md-text-center mb-5 mt-5">
              <Disclaimer className="text-uppercase">
                <FontAwesomeIcon icon={faCheck} />
                I Have Agreed To The Terms Laid Out In The Leather Spa <a href="/operational-policies" target="_blank">Operations Policies</a>
              </Disclaimer>
            </Col>
            <Col xl={5} md={6} className="pr-0 mb-3">
              <Link to="/repair-wizard" className="btn btn-outline-primary d-block">Create Another Repair</Link>
            </Col>
            <Col xl={5} md={6} className="pr-0 pl-md-2 pr-xl-2">
              <Link to="/estimates" className="btn btn-primary d-block">Choose Shipping Mode</Link>
            </Col>
          </Row>
        </Section>
        <div className="d-lg-none">
          <CancelEstimate {...{ deleteTicket, id, deletingTicket, ticketDeleted }} className="mt-5 p-0" />
        </div>
      </Col>
    </Row>
  } catch (e) {
    return <Row className="mt-4">
      <Col><h6 className="text-center">An error occured while processing your request.</h6></Col>
    </Row>
  }
}

DetailedEstimate.propTypes = {
  estimateId: PropTypes.string
}

const DetailedEstimateView = ({ estimateId }) => <AccountLayout mobile={<DetailedEstimate estimateId={estimateId} />} desktop={<DetailedEstimate estimateId={estimateId} />} />

export default DetailedEstimateView
