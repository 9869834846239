import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import scssVars from '~components/scss-variables'
import HorizontalRule from '~components/LayoutComponents/HorizontalRule'

export const StyledHeading = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: ${scssVars.darkBrown};
`

const AccountHeader = ({ title, children }) => (
  <section>
    <StyledHeading>{title}</StyledHeading>
    <HorizontalRule color={scssVars.gray} />
    {children}
  </section>
)

AccountHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default AccountHeader
